import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Caption = makeShortcode("Caption");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "color"
    }}>{`Color`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Color token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--loading__svg`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`stroke`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$interactive-04`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--loading--small`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`stroke`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$interactive-04`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--loading-overlay`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$overlay-01`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "structure"
    }}>{`Structure`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`px / rem`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Spacing token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--loading`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`height, width`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`88 / 5.5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--loading--small`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`height, width`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
      </tbody>
    </table>
    <div className="image--fixed">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "37.22826086956522%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAAsSAAALEgHS3X78AAAA5klEQVQoz2NgQAMX1VPh7P///zOkAPFUIAYBISQ1MHXI6hlwGQbS3gg1BB1cYLBBWMjAxEAQgAxdCjXsXv8Wue6687a7pp02+L9vDivCIAhmQKLxunDX/rfMIHrr8W9px+/9f73+3P+DMw785weJPfKoYJnuOUem1GU12II652WEDby74iTLJAYGpicPPme++vz/3abz/07kL/0rAJJ7YJbJWVJ6Vpih9D/Yv+zZ/wl72Rfq5U8vPsskz//vPv/wP3NgBDFjKC4kwjAYuLr1BoY8KNZvMlgwVBUdZ2DIhxgmkv4dRQ0APmBtRq8fBtgAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Large spinner structure measurements",
          "title": "Large spinner structure measurements",
          "src": "/static/434d3a7699f7c555a12a4454da758186/fb070/loading-style-1.png",
          "srcSet": ["/static/434d3a7699f7c555a12a4454da758186/d6747/loading-style-1.png 288w", "/static/434d3a7699f7c555a12a4454da758186/09548/loading-style-1.png 576w", "/static/434d3a7699f7c555a12a4454da758186/fb070/loading-style-1.png 1152w", "/static/434d3a7699f7c555a12a4454da758186/c3e47/loading-style-1.png 1472w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "loading": "lazy"
        }}></img>{`
    `}</span>
    </div>
    <Caption mdxType="Caption">
  Structure measurements for small and large loading spinner | px / rem
    </Caption>
    <h3 {...{
      "id": "placement"
    }}>{`Placement`}</h3>
    <p>{`The large loading spinner should appear centered over a page or content that it is loading. Please note that the top-level navigation is not included in the page loading overlay.`}</p>
    <div className="image--fixed">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "49.72826086956522%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAAA6ElEQVQoz9WSzwuCMBzFi/If8JL/fLf+gK7hvGSHyLpE0SGCQtDANMUfaG6u15jRZeW1Bx/emO/72Ia93l+JECLctu2BaZpj4GBvblnWghBrAbfBEntToMuZvrKQUiqcc65VVbXjCiF3Z4wZckZdmGVZ60Owquua51n6uHgRPbp3GkXhI0kSnqapm+f5SGbVhUVRCEd4CFY4hSg8uz49nK7U97w6CG4cJW5ZliM5oy5ESesa2Mnr8aZhWDGxfgnfIhQZMtupcAAmYA8csJGswRbMgP6z8CW8Uee/Io7j74EwDN/ehc+ZVk9ULxnxwVj50gAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Large spinner in context example",
          "title": "Large spinner in context example",
          "src": "/static/b1bc7e3584c542d8318b5ad704e4dc0c/fb070/loading-style-4.png",
          "srcSet": ["/static/b1bc7e3584c542d8318b5ad704e4dc0c/d6747/loading-style-4.png 288w", "/static/b1bc7e3584c542d8318b5ad704e4dc0c/09548/loading-style-4.png 576w", "/static/b1bc7e3584c542d8318b5ad704e4dc0c/fb070/loading-style-4.png 1152w", "/static/b1bc7e3584c542d8318b5ad704e4dc0c/c3e47/loading-style-4.png 1472w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "loading": "lazy"
        }}></img>{`
    `}</span>
    </div>
    <Caption mdxType="Caption">Example of a large loading spinner in product context</Caption>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      